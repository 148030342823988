const sendNonInteractionEvent = function (type, page, action) {
  // ITreview
  gtag('event', action, {
    event_category:  type,
    event_label:     page,
    non_interaction: true,
    send_to:         window.GaMeasurementId.Itreview,
  });
};

const sendTrackingEvent = function (type, page, action) {
  // ITreview
  gtag('event', action, {
    event_category: type,
    event_label:    page,
    send_to:        window.GaMeasurementId.Itreview,
  });
};

const sendPageViewEvent = function (page) {
  gtag('event', 'page_view', { page_location: page, send_to: window.GaMeasurementId.Itreview }); // ITreview
  gtag('event', 'page_view', { page_location: page, send_to: window.GaMeasurementId.LBC }); // uSonar
  gtag('event', 'page_view', { page_location: page, send_to: window.GaMeasurementId.DokoDoko }); // どこどこJP
  gtag('event', 'page_view', { page_location: page, send_to: window.GaMeasurementId.LoginInfo }); // ログイン情報
};

const sendPageViewWithCategoryEvent = function (
  page, productCategory1, productCategory2, productCategory3, productName,
) {
  // ITreview
  gtag('event', 'page_view', {
    page_location:     page,
    product_category1: productCategory1,
    product_category2: productCategory2,
    product_category3: productCategory3,
    product_name:      productName,
    send_to:           window.GaMeasurementId.Itreview,
  });
  // uSonar
  gtag('event', 'page_view', {
    page_location:     page,
    product_category1: productCategory1,
    product_category2: productCategory2,
    product_category3: productCategory3,
    product_name:      productName,
    send_to:           window.GaMeasurementId.LBC,
  });
  // どこどこJP
  gtag('event', 'page_view', {
    page_location:     page,
    product_category1: productCategory1,
    product_category2: productCategory2,
    product_category3: productCategory3,
    product_name:      productName,
    send_to:           window.GaMeasurementId.DokoDoko,
  });
  // ログイン情報
  gtag('event', 'page_view', {
    page_location:     page,
    product_category1: productCategory1,
    product_category2: productCategory2,
    product_category3: productCategory3,
    product_name:      productName,
    send_to:           window.GaMeasurementId.LoginInfo,
  });
};

const sendIntentEvent = function (type, page, action) {
  // カスタムディメンションでパラメータを受け取れないため、pagePathを上書きする
  if (type === 'alternative-products') {
    // ITreview
    gtag('event', action, {
      event_category: type,
      event_label:    page,
      page_path:      page,
      send_to:        window.GaMeasurementId.Itreview,
    });
    // uSonar
    gtag('event', action, {
      event_category: type,
      event_label:    page,
      page_path:      page,
      send_to:        window.GaMeasurementId.LBC,
    });
    // どこどこJP
    gtag('event', action, {
      event_category: type,
      event_label:    page,
      page_path:      page,
      send_to:        window.GaMeasurementId.DokoDoko,
    });
    // ログイン情報
    gtag('event', action, {
      event_category: type,
      event_label:    page,
      page_path:      page,
      send_to:        window.GaMeasurementId.LoginInfo,
    });
  } else {
    // ITreview
    gtag('event', action, {
      event_category: type,
      event_label:    page,
      send_to:        window.GaMeasurementId.Itreview,
    });
    // uSonar
    gtag('event', action, {
      event_category: type,
      event_label:    page,
      send_to:        window.GaMeasurementId.LBC,
    });
    // どこどこJP
    gtag('event', action, {
      event_category: type,
      event_label:    page,
      send_to:        window.GaMeasurementId.DokoDoko,
    });
    // ログイン情報
    gtag('event', action, {
      event_category: type,
      event_label:    page,
      send_to:        window.GaMeasurementId.LoginInfo,
    });
  }
};

const sendAlternativeProductEvent = function (type, page, params) {
  const alternativeProducts = `${page}${params}`;

  // LBC / dokodoko / loginに競合対象の製品データを送信
  sendIntentEvent(type, alternativeProducts, 'page_view');
};

$(() => {
  // レビュー投稿完了画面の表示時
  // TODO: リニューアルのレビュー投稿画面マージされたら動作確認
  const sendPosted = document.getElementById('send-posted');
  if (sendPosted) {
    const firstReviewPosted = (sendPosted.dataset.review_count === 1);
    const eventLabel = firstReviewPosted ? 'First Review Posted' : 'Review Posted';
    sendTrackingEvent(eventLabel, 'Send Review', 'open');
  }
});

export {
  sendAlternativeProductEvent, sendNonInteractionEvent,
  sendTrackingEvent, sendPageViewEvent, sendPageViewWithCategoryEvent, sendIntentEvent,
};
