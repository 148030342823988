import {
  sendPageViewEvent,
  sendPageViewWithCategoryEvent,
  sendTrackingEvent,
  sendIntentEvent,
} from './data_emitter';

const sendModalEvent = function (page) {
  sendPageViewEvent(page);
};

const setLeadModalParam = function (page, dispType, productCategory1, productCategory2, productCategory3, productName) {
  const leadModalParam = $('#leadModalParam');
  leadModalParam.attr('data-page', page);
  leadModalParam.attr('data-disp-type', dispType);
  leadModalParam.attr('data-product-category1', productCategory1);
  leadModalParam.attr('data-product-category2', productCategory2);
  leadModalParam.attr('data-product-category3', productCategory3);
  leadModalParam.attr('data-product-name', productName);
};

$(() => {
  $('.product-inquiry-btn').on('click', (e) => {
    const dispType = $(e.target).data('dispType');
    const productCategory1 = $(e.target).data('productCategory1');
    const productCategory2 = $(e.target).data('productCategory2');
    const productCategory3 = $(e.target).data('productCategory3');
    const productName = $(e.target).data('productName');
    const page = e.target.pathname;
    const returnUrl = e.target.search.slice(1);
    const virtualUrl = decodeURIComponent(`${page}?type=${dispType}&status=input&${returnUrl}`);
    setLeadModalParam(page, dispType, productCategory1, productCategory2, productCategory3, productName);

    sendPageViewWithCategoryEvent(virtualUrl, productCategory1, productCategory2, productCategory3, productName);
    sendModalEvent(virtualUrl);
  });

  $('.first-mypage-button-submit').on('click', (e) => {
    const uuid = $(e.target).data('uuid');

    // ITreview
    gtag('event', 'click', {
      event_category: 'Member Registration',
      event_label:    uuid,
      send_to:        window.GaMeasurementId.Itreview,
    });
    // uSonar
    gtag('event', 'click', {
      event_category: 'Member Registration',
      event_label:    uuid,
      send_to:        window.GaMeasurementId.LBC,
    });
    // どこどこJP
    gtag('event', 'click', {
      event_category: 'Member Registration',
      event_label:    uuid,
      send_to:        window.GaMeasurementId.DokoDoko,
    });
    // ログイン情報
    gtag('event', 'click', {
      event_category: 'Member Registration',
      event_label:    uuid,
      send_to:        window.GaMeasurementId.LoginInfo,
    });
  });

  // ウェビナーページで申込ボタン押下時
  $('.webinar-click-event').on('click', (e) => {
    const seminarId = $(e.target).data('seminarId');
    const vendorName = $(e.target).data('vendorName');
    const productName = $(e.target).data('productName');
    const heldAt = $(e.target).data('heldAt');
    const seminarTitle = $(e.target).data('seminarTitle');
    const eventLabel = `${vendorName}:${productName}:${heldAt}:${seminarTitle}:${seminarId}`;
    sendTrackingEvent('Vendor Event/Webinar Link', eventLabel, 'click');

    const productUrlName = $(e.target).data('productUrlName');
    const page = `${window.location.pathname}?seminar_id=${seminarId}&product_url_name=${productUrlName}`;
    sendIntentEvent('event-detail', page, 'click');
  });

  // イベントトラッキング（動画資料ボタン押下時）
  $('a.product-movie-paper-btn').click((e) => {
    const dispType = $(e.currentTarget).data('dispType');
    const productCategory1 = $(e.currentTarget).data('productCategory1');
    const productCategory2 = $(e.currentTarget).data('productCategory2');
    const productCategory3 = $(e.currentTarget).data('productCategory3');
    const productName = $(e.currentTarget).data('productName');
    const page = e.currentTarget.pathname;
    const returnUrl = e.currentTarget.search.slice(1);
    const virtualUrl = decodeURIComponent(`${page}?type=${dispType}&status=input&${returnUrl}`);
    setLeadModalParam(page, dispType, productCategory1, productCategory2, productCategory3, productName);

    sendPageViewWithCategoryEvent(virtualUrl, productCategory1, productCategory2, productCategory3, productName);
  });

  // イベントトラッキング（トライアルボタン押下時）
  // NOTE: product-trial-btn は無料製品画面でリンク型のリードキャンペーンがある場合のみ設定される
  // ただし現状リンク型のリードキャンペーンは設定できないためこちらも実質的に無効状態であると思われる
  $('a.product-trial-btn').click((e) => {
    const items = {
      eventAction: e.target.dataset.eventAction,
      productName: e.target.dataset.productName,
    };
    const { eventAction } = items;
    const { productName } = items;

    sendTrackingEvent('Vendor Site Link', `${productName} ${eventAction}`, 'click');
  });

  $('.modal-show-event').on('click', (e) => {
    const page = e.currentTarget.pathname + e.currentTarget.search;
    sendModalEvent(page);
  });
});
